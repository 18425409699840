import './styles.scss';

import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { message } from 'antd';
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver';
import Modal from 'antd/es/modal';
import Slider from 'antd/es/slider';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

import { convertImage, getFileExtension } from '../../shared/utils';
import Loader from '../Loader';

export const modalBodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 12,
  justifyContent: 'space-between',
  padding: '0 16px',
  paddingTop: 16,
  overflow: 'hidden',
};

const pkg = 'antd-img-crop';
const noop = () => {};

const MEDIA_CLASS = `${pkg}-media`;

const ZOOM_STEP = 0.1;

const MIN_ROTATE = 0;
const MAX_ROTATE = 360;
const ROTATE_STEP = 1;

const EasyCrop = forwardRef((props, ref) => {
  const {
    src,
    aspect,
    shape,
    grid,

    hasZoom,
    zoomVal,
    rotateVal,
    setZoomVal,
    setRotateVal,

    minZoom,
    maxZoom,
    onComplete,

    cropperProps,
    loading,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onComplete(croppedAreaPixels);
    },
    [onComplete],
  );

  const onMediaLoaded = useCallback(
    (mediaSize) => {
      const { width, height } = mediaSize;
      const ratioWidth = height * aspect;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspect });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aspect, src],
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Cropper
          {...cropperProps}
          ref={ref}
          image={src}
          crop={crop}
          cropSize={cropSize}
          onCropChange={setCrop}
          aspect={aspect}
          cropShape={shape}
          showGrid={grid}
          zoomWithScroll={hasZoom}
          zoom={zoomVal}
          rotation={rotateVal}
          onZoomChange={setZoomVal}
          onRotationChange={setRotateVal}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onCropComplete={onCropComplete}
          onMediaLoaded={onMediaLoaded}
          classes={{
            containerClassName: `${pkg}-container`,
            mediaClassName: MEDIA_CLASS,
          }}
        />
      )}
    </>
  );
});

EasyCrop.propTypes = {
  src: PropTypes.string,
  aspect: PropTypes.number,
  shape: PropTypes.string,
  grid: PropTypes.bool,

  hasZoom: PropTypes.bool,
  zoomVal: PropTypes.number,
  rotateVal: PropTypes.number,
  setZoomVal: PropTypes.func,
  setRotateVal: PropTypes.func,

  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  onComplete: PropTypes.func,
  loading: PropTypes.bool,

  cropperProps: PropTypes.shape(),
};

const ImgCrop = forwardRef((props, ref) => {
  const {
    aspect,
    shape,
    grid,
    quality,

    zoom,
    rotate,
    minZoom,
    maxZoom,
    fillColor,

    modalTitle,
    modalWidth,
    modalOk,
    modalCancel,

    beforeCrop,
    children,

    cropperProps,
  } = props;

  const hasZoom = zoom === true;
  const hasRotate = rotate === true;

  const [src, setSrc] = useState('');
  const [zoomVal, setZoomVal] = useState(1);
  const [rotateVal, setRotateVal] = useState(0);
  const [loading, setloading] = useState(false);
  const [view, setView] = useState(false);

  const beforeUploadRef = useRef();
  const fileRef = useRef();
  const resolveRef = useRef(noop);
  const rejectRef = useRef(noop);

  const cropPixelsRef = useRef();

  useEffect(() => {
    setView(false);
  }, []);

  const setImage = (fileData, url) => {
    const image = new Image();
    image.onload = function () {
      setloading(false);
      setSrc(fileData);
    };
    image.onerror = function () {
      setView(false);
      message.error('Image is not valid');
    };
    image.src = url;
  };

  /**
   * Upload
   */
  const renderUpload = useCallback(() => {
    const upload = Array.isArray(children) ? children[0] : children;
    const { beforeUpload, accept, ...restUploadProps } = upload.props;
    beforeUploadRef.current = beforeUpload;

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        beforeUpload: (file, fileList) =>
          new Promise((resolve, reject) => {
            if (beforeCrop && !beforeCrop(file, fileList)) {
              reject();
              return;
            }

            fileRef.current = file;
            resolveRef.current = resolve;
            rejectRef.current = reject;

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              setView(true);
              setloading(true);
              const type = getFileExtension(file.name)[1].toLowerCase();
              const isImage =
                type === 'jpg' ||
                type === 'png' ||
                type === 'gif' ||
                type === 'webp' ||
                type === 'tiff' ||
                type === 'jpeg' ||
                type === 'heic' ||
                type === 'svg';
              if (!isImage) {
                // message.error("You can only valid image type!");
                setView(false);
                message.error('You can only put a valid image here!');
                return false;
              }
              convertImage(type, file, setImage, true, reader.result);
            });
            reader.readAsDataURL(file);
          }),
      },
    };
  }, [beforeCrop, children]);

  /**
   * EasyCrop
   */
  const onComplete = useCallback((croppedAreaPixels) => {
    cropPixelsRef.current = croppedAreaPixels;
  }, []);

  /**
   * Controls
   */
  const isMinZoom = zoomVal - ZOOM_STEP < minZoom;
  const isMaxZoom = zoomVal + ZOOM_STEP > maxZoom;
  const isMinRotate = rotateVal === MIN_ROTATE;
  const isMaxRotate = rotateVal === MAX_ROTATE;

  const subZoomVal = useCallback(() => {
    if (!isMinZoom) setZoomVal(zoomVal - ZOOM_STEP);
  }, [isMinZoom, zoomVal]);

  const addZoomVal = useCallback(() => {
    if (!isMaxZoom) setZoomVal(zoomVal + ZOOM_STEP);
  }, [isMaxZoom, zoomVal]);

  const subRotateVal = useCallback(() => {
    if (!isMinRotate) setRotateVal(rotateVal - ROTATE_STEP);
  }, [isMinRotate, rotateVal]);

  const addRotateVal = useCallback(() => {
    if (!isMaxRotate) setRotateVal(rotateVal + ROTATE_STEP);
  }, [isMaxRotate, rotateVal]);

  /**
   * Modal
   */
  const modalProps = useMemo(() => {
    const obj = {
      width: modalWidth,
      okText: modalOk,
      cancelText: modalCancel,
      zIndex: '10000',
    };

    Object.keys(obj).forEach((key) => {
      if (!obj[key]) delete obj[key];
    });

    return obj;
  }, [modalCancel, modalOk, modalWidth]);

  const onClose = useCallback(() => {
    setView(false);
    setSrc('');
    setZoomVal(1);
    setRotateVal(0);
  }, []);

  const onOk = useCallback(async () => {
    onClose();

    const naturalImg = document.querySelector(`.${MEDIA_CLASS}`);
    const { naturalWidth, naturalHeight } = naturalImg;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // create a max canvas to cover the source image after rotated
    const maxLen = Math.sqrt(naturalWidth ** 2 + naturalHeight ** 2);
    canvas.width = maxLen;
    canvas.height = maxLen;

    // rotate the image
    if (hasRotate && rotateVal > 0 && rotateVal < 360) {
      const halfMax = maxLen / 2;
      ctx.translate(halfMax, halfMax);
      ctx.rotate((rotateVal * Math.PI) / 180);
      ctx.translate(-halfMax, -halfMax);
    }

    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // draw the source image in the center of the max canvas
    const left = (maxLen - naturalWidth) / 2;
    const top = (maxLen - naturalHeight) / 2;
    ctx.drawImage(naturalImg, left, top);

    // shrink the max canvas to the crop area size, then align two center points
    const maxImgData = ctx.getImageData(0, 0, maxLen, maxLen);
    const { width, height, x, y } = cropPixelsRef.current;
    canvas.width = width;
    canvas.height = height;
    ctx.putImageData(maxImgData, Math.round(-left - x), Math.round(-top - y));

    // get the new image
    const { type, name, uid } = fileRef.current;
    canvas.toBlob(
      async (blob) => {
        let newFile = new File([blob], name, { type });
        newFile.uid = uid;

        if (typeof beforeUploadRef.current !== 'function')
          return resolveRef.current(newFile);

        const res = beforeUploadRef.current(newFile, [newFile]);

        if (typeof res !== 'boolean' && !res) {
          console.error('beforeUpload must return a boolean or Promise');
          return;
        }

        if (res === true) return resolveRef.current(newFile);
        if (res === false) return rejectRef.current('not upload');
        if (res && typeof res.then === 'function') {
          try {
            const passedFile = await res;
            const parsedType = Object.prototype.toString.call(passedFile);
            if (
              parsedType === '[object File]' ||
              parsedType === '[object Blob]'
            )
              newFile = passedFile;
            resolveRef.current(newFile);
          } catch (err) {
            rejectRef.current(err);
          }
        }
      },
      type,
      quality,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRotate, onClose, quality, rotateVal]);

  const renderComponent = (titleOfModal) => (
    <>
      {renderUpload()}
      <Modal
        open={view}
        wrapClassName={`${pkg}-modal`}
        title={titleOfModal}
        onOk={onOk}
        onCancel={onClose}
        bodyStyle={modalBodyStyle}
        centered
        maskClosable={false}
        destroyOnClose
        {...modalProps}>
        {/* {loading && <Loader />} */}
        <EasyCrop
          ref={ref}
          src={src}
          aspect={aspect}
          shape={shape}
          grid={grid}
          hasZoom={hasZoom}
          zoomVal={zoomVal}
          rotateVal={rotateVal}
          setZoomVal={setZoomVal}
          setRotateVal={setRotateVal}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onComplete={onComplete}
          cropperProps={cropperProps}
          loading={loading}
        />
        {hasZoom && (
          <div className={`${pkg}-control zoom`}>
            <button onClick={subZoomVal} disabled={isMinZoom} type="button">
              －
            </button>
            <Slider
              min={minZoom}
              max={maxZoom}
              step={ZOOM_STEP}
              value={zoomVal}
              onChange={setZoomVal}
            />
            <button onClick={addZoomVal} disabled={isMaxZoom} type="button">
              ＋
            </button>
          </div>
        )}
        {hasRotate && (
          <div className={`${pkg}-control rotate`}>
            <button onClick={subRotateVal} disabled={isMinRotate} type="button">
              ↺
            </button>
            <Slider
              min={MIN_ROTATE}
              max={MAX_ROTATE}
              step={ROTATE_STEP}
              value={rotateVal}
              onChange={setRotateVal}
            />
            <button onClick={addRotateVal} disabled={isMaxRotate} type="button">
              ↻
            </button>
          </div>
        )}
      </Modal>
    </>
  );

  if (modalTitle) return renderComponent(modalTitle);

  return (
    <>
      <LocaleReceiver>
        {(locale, localeCode) =>
          renderComponent(localeCode === 'zh-cn' ? '编辑图片' : 'Edit image')
        }
      </LocaleReceiver>
    </>
  );
});

ImgCrop.propTypes = {
  aspect: PropTypes.number,
  shape: PropTypes.oneOf(['rect', 'round']),
  grid: PropTypes.bool,
  quality: PropTypes.number,

  zoom: PropTypes.bool,
  rotate: PropTypes.bool,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  fillColor: PropTypes.string,

  modalTitle: PropTypes.string,
  modalWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalOk: PropTypes.string,
  modalCancel: PropTypes.string,

  beforeCrop: PropTypes.func,
  cropperProps: PropTypes.shape(),

  children: PropTypes.node,
};

ImgCrop.defaultProps = {
  aspect: 1,
  shape: 'rect',
  grid: true,
  quality: 1,

  zoom: true,
  rotate: true,
  minZoom: 1,
  maxZoom: 3,
  fillColor: 'white',
};

export default ImgCrop;
