import React, { useState } from 'react';

import { Typography } from 'antd';
import ReactHtmlParser from 'html-react-parser';
import ReactLinkify from 'react-linkify';

import { useTheme } from '../../../../context/ThemeProvider';

interface Props {
  children: string;
  truncatedLength?: number;
  lineLength?: number;
}

const SeeMoreMessageContents: React.FC<Props> = ({
  children,
  truncatedLength = 660,
}) => {
  const { colors } = useTheme();

  const length = children.length;

  const truncationNeeded = length >= truncatedLength;
  const [truncated, setTruncated] = useState(truncationNeeded);

  const truncatedText = children.substring(0, truncatedLength - 1);
  // const [truncationNeeded, setTruncationNeeded] = useState(false);
  // const [truncatedText, setTruncatedText] = useState('');

  // const morphText = useCallback(
  //   (text: string) => {
  //     let sub =
  //       text.length >= truncatedLength
  //         ? text.substring(0, truncatedLength - 1)
  //         : text;

  //     if (text.length >= truncatedLength) {
  //       setTruncatedText(sub);
  //       setTruncated(true);
  //       setTruncationNeeded(true);
  //     }
  //     const matches = sub.matchAll(/\n/g);

  //     const matchesArray = Array.from(matches);
  //     if (matchesArray?.length > Math.round(truncatedLength / lineLength)) {
  //       // truncate the text even further
  //       sub = sub.substring(
  //         0,
  //         matchesArray[Math.round(truncatedLength / lineLength)].index ||
  //           truncatedLength - 1,
  //       );
  //       setTruncatedText(sub);
  //       setTruncated(true);
  //       setTruncationNeeded(true);
  //     }
  //   },
  //   [lineLength, truncatedLength],
  // );

  // useEffect(() => {
  //   morphText(children);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [children]);

  return (
    <>
      <Typography.Paragraph className="message-seemore">
        {truncationNeeded && truncated ? (
          <>
            <ReactLinkify>
              {ReactHtmlParser(truncatedText.replaceAll('\n', '<br />'))}
            </ReactLinkify>
            {truncationNeeded && (
              <span
                style={{
                  color: colors.LINK,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTruncated(false);
                }}
                role="button">
                {' ...Read more'}
              </span>
            )}
          </>
        ) : (
          <>
            <ReactLinkify>
              {ReactHtmlParser(children.replaceAll('\n', '<br />'))}
            </ReactLinkify>

            {truncationNeeded && (
              <span
                style={{
                  color: colors.LINK,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTruncated(true);
                }}
                role="button">
                {' Read less'}
              </span>
            )}
          </>
        )}
      </Typography.Paragraph>
    </>
  );
};

export default React.memo(SeeMoreMessageContents);
