import './styles.scss';

import React, { useMemo } from 'react';

import { Button, message, Typography } from 'antd';
import { IoLockClosed } from 'react-icons/io5';

import CustomImage from '../../../components/CustomImage/CustomImage';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { createTag } from '../../../shared/utils';
import { CourseTypes, MangoArr } from '../../../types/courseTypes';
import MangoTitle from '../../Feed/Posts/MangoTitle';

interface Props {
  onPress: () => void;
  type: CourseTypes;
  title: string;
  mangoes: MangoArr[];
  coverImage: string | null;
  totalProgress?: number;
  totalLectures?: number;
  courseExpired?: boolean;

  // paid course
  purchaseRequired?: boolean;
  previewChapters?: number;
  mangoToSell?: string;
}

const CourseListCard: React.FC<Props> = ({
  type,
  title,
  mangoes,
  coverImage,
  totalProgress = 0,
  totalLectures = 0,
  courseExpired = false,
  onPress,

  // paid course
  purchaseRequired,
  previewChapters,
  mangoToSell,
}) => {
  const { colors } = useTheme();

  const { deeplinkUrl } = useAppSelector((state) => state.app);

  const tag = useMemo(() => {
    if (purchaseRequired) {
      if (previewChapters) {
        return createTag('FREE PREVIEW', 'success', colors);
      }
      return null;
    }
    if (type === 'purchased') {
      if (courseExpired) {
        return createTag('EXPIRED', 'danger', colors);
      }
      if (totalProgress === 100) {
        return createTag('COMPLETED', 'success', colors);
      }
      if (totalProgress === 0) {
        return createTag('NEW', 'primary', colors);
      }
    }
    return null;
  }, [
    colors,
    courseExpired,
    previewChapters,
    purchaseRequired,
    totalProgress,
    type,
  ]);

  return (
    <>
      <div onClick={onPress} className="course__list__card">
        <div className="course__list__card__image__wrapper">
          <CustomImage
            src={
              coverImage ||
              'https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp'
            }
            fallbackSrc="https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp"
            className="course__list__card__image"
          />
          {purchaseRequired ? (
            <div className="course__list__card__image__overlay">
              <div className="course__list__card__image__overlay__icon">
                <IoLockClosed />
              </div>
            </div>
          ) : null}
        </div>
        <div className="course__list__card__content">
          <div className="course__list__content">
            {/* {!hideMenu ? (
              <div className="course__list__card__tag__header">
                <div className="course__list__card__tag__wrapper">{tag}</div>
              </div>
            ) : null} */}
            <div className="course__list__card__title__wrapper">
              {/* <Typography.Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: false,
                }}
                className="course__list__card__subtitle">
                {subTitle}
              </Typography.Paragraph> */}
              <MangoTitle mangoes={mangoes as any} />
              {tag}
            </div>
            <Typography.Title
              ellipsis={{
                rows: 2,
                expandable: false,
              }}
              level={5}
              className="course__list__card__title">
              {title}
            </Typography.Title>
          </div>

          {purchaseRequired ? (
            <Button
              type="primary"
              className="siteBtn siteBtnPrimary course__list__card__button"
              onClick={() => {
                if (mangoToSell) {
                  const url = `${deeplinkUrl}web/checkout/${mangoToSell}`;
                  window.open(url, '_blank');
                } else {
                  message.error('Course not available for purchase!');
                }
              }}>
              Buy now to unlock
            </Button>
          ) : null}

          {type === 'purchased' &&
          !courseExpired &&
          totalProgress > 0 &&
          totalProgress < 100 &&
          !purchaseRequired ? (
            <>
              <div style={{ marginTop: '8px' }}>
                <>
                  <div className="progress__content">
                    <Typography.Text
                      style={{
                        color: colors.PRIMARY,
                      }}>
                      {totalProgress.toFixed(0)}% complete
                    </Typography.Text>
                    <Typography.Text>{totalLectures} lectures</Typography.Text>
                  </div>
                  <div className="progressbar">
                    <div
                      className="progressbar__inner"
                      style={{
                        width: `${totalProgress}%`,
                      }}
                    />
                  </div>
                </>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default React.memo(CourseListCard);
