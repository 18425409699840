import '../styles.scss';

import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { Avatar, Button, Typography } from 'antd';
import { IoCaretDown, IoClose } from 'react-icons/io5';

import MangoSelectionModal from '../../../components/MangoSelectionModal/MangoSelectionModal';
import { useAppSelector } from '../../../shared/hooks';
import { IMango } from '../../../types/feedTypes';
import NoDataFound from '../../Feed/components/NoDataFound/NoDataFound';
import TagSelectionModal from '../../Feed/Posts/PostItem/TagSelectionModal';

interface Props {
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  selectedMangoes: IMango[];
  setSelectedMangoes: React.Dispatch<React.SetStateAction<IMango[]>>;
  profilePicUrl?: string;
  creatorName?: string;
  customTitle?: string;
  customDescription?: string;
  canEditSubscriberPost?: boolean;
}

export interface CreatePostHeaderRef {
  openTagModal: () => void;
}

const CreatePostHeader: React.ForwardRefRenderFunction<
  CreatePostHeaderRef,
  Props
> = (
  {
    selectedTags,
    setSelectedTags,
    selectedMangoes,
    setSelectedMangoes,
    profilePicUrl,
    creatorName,
    customTitle,
    customDescription,
    canEditSubscriberPost,
  },
  ref,
) => {
  const {
    hostMetadata: {
      offeringTitle,
      offeringTitles,
      creator,
      communityEnabledMangoes,
    },
    tags: appTags,
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );

  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [isMangoModalOpen, setIsMangoModalOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openTagModal: () => {
      setIsTagModalOpen(true);
    },
  }));

  const tagItem = (key: string, value: string) => (
    <div
      onClick={() => {
        setSelectedTags((prev) => prev.filter((x) => x !== key));
      }}
      key={key}
      className={`post-tag-item ${key}`}>
      <Typography.Text className="post-tag-item-text">{value}</Typography.Text>

      <IoClose size={15} />
    </div>
  );

  const mangoesToSelectFrom = useMemo(() => {
    if (
      ['creator_completed', 'dual', 'creator_restricted'].includes(
        userDetails.type || '',
      )
    ) {
      return creatorMangoes;
    } else if (
      subscribedMangoes.length > 0 &&
      communityEnabledMangoes &&
      communityEnabledMangoes.length > 0 &&
      ['fan_completed'].includes(userDetails.type || '')
    ) {
      const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
        (mango) => communityEnabledMangoes.includes(mango._id),
      );
      return subscribedMangoesWithCommunityEnabled;
    }
    return [];
  }, [
    creatorMangoes,
    subscribedMangoes,
    userDetails.type,
    communityEnabledMangoes,
  ]);

  return (
    <>
      {selectedTags.length > 0 && (
        <div className="create-post-tags">
          {selectedTags.map((tag) => tagItem(tag, appTags[tag]?.value || ''))}
        </div>
      )}
      <div className="create-post-header">
        <Avatar
          size={55}
          src={
            profilePicUrl ||
            (userDetails.type === 'creator_restricted'
              ? creator.profilePicUrl
              : userDetails.profilePic)
          }
        />
        <div className="create-post-header-details">
          <Typography.Title level={5}>
            {creatorName ||
              (userDetails.type === 'creator_restricted'
                ? creator?.name || ''
                : userDetails.name)}
          </Typography.Title>
          <div className="create-post-header-details-mango">
            <Button
              className="siteBtn create-post-header-details-mango-button"
              onClick={() => setIsMangoModalOpen(true)}
              disabled={canEditSubscriberPost}
              style={{
                opacity: canEditSubscriberPost ? 0.5 : 1,
              }}>
              {selectedMangoes.length > 0 ? (
                <Typography.Text>
                  {selectedMangoes[0].title}{' '}
                  {selectedMangoes.length > 1
                    ? `+${selectedMangoes.length - 1}`
                    : ''}
                </Typography.Text>
              ) : (
                <Typography.Text>
                  + {offeringTitle.toTitleCase()}
                </Typography.Text>
              )}
              <IoCaretDown size={14} />
            </Button>
          </div>
        </div>
      </div>
      <TagSelectionModal
        selectedTags={selectedTags}
        show={isTagModalOpen}
        closeModal={() => setIsTagModalOpen(false)}
        handleSave={(tags) => {
          setSelectedTags(tags);
          setIsTagModalOpen(false);
        }}
      />
      <MangoSelectionModal
        mangoes={mangoesToSelectFrom}
        selectedMangoes={selectedMangoes}
        handleSave={(m) => {
          setSelectedMangoes(m);
          setIsMangoModalOpen(false);
        }}
        customTitle={customTitle}
        customDescription={customDescription}
        show={isMangoModalOpen}
        closeModal={() => setIsMangoModalOpen(false)}
        noMangoComponent={
          <NoDataFound
            title={`No ${offeringTitles} found`}
            subtitle={
              userDetails.type === 'fan_completed'
                ? `You have not subscribed to any ${offeringTitles} yet. Subscribe to one to start posting content.`
                : `You have not created any ${offeringTitles} yet. Create one to start creating content.`
            }
          />
        }
      />
    </>
  );
};

export default forwardRef(CreatePostHeader);
