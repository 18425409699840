import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import moment from 'moment';

import FeedAPI from '../../api/FeedAPI';
import { onRefresh } from '../../context/activityReducer';
import { useAppProvider } from '../../context/AppProvider';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { IWebinar, IWebinarParticipant } from '../../types/feedTypes';
import VideoCallMenu from '../Feed/VideoCall/VideoCallMenu';
import WorkshopFlatlist from './WorkshopFlatlist';

interface Props {}

interface IState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  webinars: IWebinar[];
  refreshing: boolean;
}

export interface RefProps {
  refreshPage: () => void;
}

const UpcomingWorkshopsScreen: ForwardRefRenderFunction<RefProps, Props> = (
  {},
  ref,
) => {
  const SIZE = 10;
  const { onMeetingJoin } = useAppProvider();
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector((state) => state.activity);
  const [selectedWebinar, setSelectedWebinar] = useState<IWebinar | null>(null);
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [state, setState] = useState<IState>({
    loading: false,
    page: 1,
    hasMore: true,
    webinars: [],
    refreshing: false,
  });

  const getWebinars = async (
    pageCount: any,
    webinarList = state.webinars,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading) {
      setState({ ...currState, loading: true });
      try {
        const res = await FeedAPI.getCreatorWebinars({
          status: 'upcoming',
          page: pageCount,
          limit: SIZE,
        });
        let newState = { ...currState, loading: false, refreshing: false };
        if (res.result && res.result.videocalls.length) {
          const newVideoCalls = [...res.result.videocalls].filter(
            (i) => i.toTime && moment(i.toTime).isAfter(moment()),
          );
          const updatedWebinars =
            pageCount > 1
              ? [...webinarList, ...newVideoCalls]
              : [...newVideoCalls];
          newState = {
            ...newState,
            webinars: updatedWebinars,
            page: pageCount + 1,
            hasMore: res.result.videocalls.length === SIZE,
          };
          if (newVideoCalls.length === 0) {
            getWebinars(pageCount + 1, updatedWebinars, true, false);
          }
        } else {
          newState = {
            ...newState,
            webinars: [...webinarList],
            hasMore: false,
          };
        }
        setState({ ...state, ...newState });
      } catch (error: any) {
        setState({ ...state, loading: false });
      }
    }
  };

  const refreshPage = () => {
    dispatch(onRefresh());
    const newState = {
      webinars: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: true,
    };
    setState((prev) => ({
      ...prev,
      webinars: [],
      hasMore: true,
      page: 1,
    }));
    if (selected) {
      getWebinars(1, [], true, false, newState);
    }
  };

  useEffect(() => {
    const newState = {
      webinars: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setState((prev) => ({
      ...prev,
      webinars: [],
      hasMore: true,
      page: 1,
    }));
    if (selected) {
      getWebinars(1, [], true, false, newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const goToMeeting = () => {};

  const onMeetingClick = async (slot: IWebinar) => {
    onMeetingJoin(slot);
  };

  useImperativeHandle(ref, () => ({
    refreshPage: () => {
      refreshPage();
    },
  }));

  return (
    <VideoCallMenu
      selectedWebinar={selectedWebinar}
      setSelectedWebinar={setSelectedWebinar}
      isMenuVisible={isBottomSheetVisible}
      isDetailsVisible={isDetailsModalVisible}
      onMenuClose={() => setIsBottomSheetVisible(false)}
      onDetailsClose={() => setIsDetailsModalVisible(false)}
      host={
        selectedWebinar?.participants.find(
          (el: any) => el._id === selectedWebinar.creator,
        ) || (selectedWebinar?.participants[0] as IWebinarParticipant)
      }
      refreshPage={refreshPage}
      showDetails={() => {
        setIsBottomSheetVisible(false);
        setIsDetailsModalVisible(true);
      }}
      onJoinBtnClick={onMeetingClick}>
      <WorkshopFlatlist
        refreshing={state.refreshing}
        onRefreshed={refreshPage}
        loading={state.loading}
        hasMore={state.hasMore}
        webinarType="upcoming"
        webinars={state.webinars}
        getMoreWebinars={() => getWebinars(state.page, state.webinars)}
        onMenuPress={(data) => {
          setSelectedWebinar(data);
          setIsBottomSheetVisible(true);
        }}
        onDetailsPress={(data) => {
          setSelectedWebinar(data);
          setIsDetailsModalVisible(true);
        }}
        onJoinBtnClick={onMeetingClick}
      />
    </VideoCallMenu>
  );
};

export default forwardRef(UpcomingWorkshopsScreen);
