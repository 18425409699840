import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Avatar, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import { BottomSheet } from 'react-spring-bottom-sheet';

import DeleteSvg from '../../../assets/svg/Delete';
import TickSvg from '../../../assets/svg/TickSvg';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import ImageAttachment from '../../../components/ImageAttachment/ImageAttachment';
import MenuButton from '../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import { linkifyContent, timeAgo } from '../../../shared/utils';

interface Props {
  type?: 'comment' | 'question';
  attachments?: string[];
  question: string;
  description?: string;
  createdAt: Date;
  imgUrl?: string;
  replyCount?: number;
  userName: string;
  containerStyle?: React.CSSProperties;
  showReply?: boolean;
  onClick?: () => void;
  isAnswered?: boolean;
  showMenu?: boolean;
  onRemoveItem?: () => void;
}

const QNAItem: React.FC<Props> = ({
  type = 'question',
  question,
  description,
  createdAt,
  imgUrl,
  userName,
  replyCount,
  attachments,
  containerStyle = {},
  showReply = false,
  onClick = () => {},
  isAnswered = false,
  showMenu = false,
  onRemoveItem = () => {},
}) => {
  const { colors } = useTheme();
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const linkifiedDescription = useMemo(() => {
    return description ? linkifyContent(description) : null;
  }, [description]);

  return (
    <>
      <div className="qna__item" style={containerStyle}>
        <Avatar size={29} src={imgUrl} />
        <div style={{ marginLeft: 12, width: '100%' }}>
          <div className="qna__item__header">
            <Typography.Title className="qna__item__header__title" level={4}>
              {userName}{' '}
              <Typography.Text className="qna__item__header__title__time">
                • {timeAgo(createdAt)}
              </Typography.Text>
            </Typography.Title>
            {showMenu && showReply ? (
              <div className="qna__item__header__menu__wrapper">
                <MenuButton
                  className="qna__item__header__menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowBottomSheet(true);
                  }}
                />
              </div>
            ) : null}
          </div>
          {question ? (
            <Typography.Title className="qna__item__question" level={3}>
              {question}
            </Typography.Title>
          ) : null}
          {linkifiedDescription ? (
            <div
              style={{
                marginTop: 6,
              }}>
              <Typography.Paragraph
                ellipsis={{ rows: 3, expandable: true, symbol: 'See more' }}
                className="qna__item__description">
                {HtmlParser(linkifiedDescription)}
              </Typography.Paragraph>
            </div>
          ) : null}
          {attachments && attachments?.length > 0 ? (
            <ImageAttachment
              containerStyle={{
                marginTop: 12,
              }}
              images={attachments}
              maxImagesAllowed={2}
            />
          ) : null}
          {showReply && replyCount ? (
            <Typography.Title
              onClick={onClick}
              className="qna__item__reply"
              level={5}>
              {replyCount} Repl{replyCount > 1 ? 'ies' : 'y'}
              {isAnswered ? (
                <>
                  <Typography.Text className="qna__item__reply__separator">
                    |
                  </Typography.Text>
                  <Typography.Text className="qna__item__reply__answered">
                    <TickSvg
                      circleColor={colors.GREEN}
                      backgroundColor={colors.BACKGROUND}
                      height={12}
                      width={12}
                    />{' '}
                    Answered
                  </Typography.Text>
                </>
              ) : null}
            </Typography.Title>
          ) : null}
        </div>
      </div>
      <BottomSheet
        open={showBottomSheet}
        onDismiss={() => setShowBottomSheet(false)}>
        <BottomSheetTile
          key={1}
          icon={<DeleteSvg width={20} height={20} />}
          warning
          alignIcon="flex-start"
          title={`Remove ${type}`}
          description={
            type === 'comment'
              ? 'This comment will be removed from this thread'
              : 'The question and all of its replies will be removed from this thread'
          }
          onClick={() => {
            setShowBottomSheet(false);
            setIsDialogVisible(true);
          }}
        />
      </BottomSheet>
      <ConfirmationModal
        open={isDialogVisible}
        message={`Do you really want to delete this ${type}?`}
        title="Remove"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          setIsDialogVisible(false);
          onRemoveItem();
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(QNAItem);
