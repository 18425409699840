import './styles.scss';

import React, { createRef, useCallback, useEffect, useState } from 'react';

import { Button, Input, message } from 'antd';
import { MdAdd, MdClose, MdSend } from 'react-icons/md';
import TMIcon from 'tm-icons-library';

import { nanoid } from '@reduxjs/toolkit';

import ChatAPI from '../../../../api/ChatAPI';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppSelector } from '../../../../shared/hooks';
import { ChatAttachmentTypes } from '../../../../shared/messageUtils';
import { getFileExtension } from '../../../../shared/utils';
import { IMessage } from '../../../../types/chatModels/ChatMessagesResponse';
import {
  SendMessageRequest,
  SendMessageResult,
} from '../../../../types/chatModels/SendMessage';
import FileSelectionModal from '../../components/FileSelectionModal/FileSelectionModal';
import ReplyContents from '../../components/ReplyContents/ReplyContents';

interface Props {
  noMedia?: boolean;
  roomId?: string;
  otherParticipantId?: string;
  onNewRoomCreated: (newRoomId: string) => void;
  onMessageSent: (
    message: SendMessageResult,
    type: 'sending' | 'sent' | 'received',
  ) => void;
  shouldSendMessage?: (message: string) => Promise<boolean>;
  replyTo?: IMessage;
  onCloseReply: () => void;
  replyNameColor?: string;
  showScrollDown: boolean;
  scrollToBottom: () => void;
}

export interface FileData {
  fileType: ChatAttachmentTypes;
  url: string;
  file: File;
}

const ChatInputBox: React.FC<Props> = ({
  noMedia,
  replyTo,
  onCloseReply,
  replyNameColor,
  shouldSendMessage,
  roomId,
  otherParticipantId,
  onMessageSent,
  onNewRoomCreated,
  showScrollDown,
  scrollToBottom,
}) => {
  const { colors } = useTheme();

  const { id, name, profilePic } = useAppSelector((state) => state.user);

  // const inputRef = useRef<HTMLDivElement>(null);
  const inputRef = createRef<HTMLTextAreaElement>();

  const [isSaving, setIsSaving] = useState(false);
  const [text, setText] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFile, setSelectedFile] = useState<FileData>();
  const [showFileSelector, setShowFileSelector] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileType = (getFileExtension(file.name) || [
        '',
        '',
      ])[1].toLowerCase();

      let type: ChatAttachmentTypes = 'doc';

      if (
        ['jpg', 'png', 'gif', 'webp', 'tiff', 'jpeg', 'heic', 'svg'].includes(
          fileType,
        )
      ) {
        type = 'image';
      } else if (
        ['mp4', 'webm', 'quicktime', 'mov', 'mkv'].includes(fileType)
      ) {
        type = 'video';
      } else if (
        file.type &&
        [
          'video/mp4',
          'video/webm',
          'video/quicktime',
          'video/mov',
          'video/mkv',
        ].includes(file.type)
      ) {
        type = 'video';
      } else if (
        ['mp3', 'm4a', 'aac', 'm3u', 'oga', 'wav'].includes(fileType)
      ) {
        type = 'audio';
      }

      if (type !== 'doc' && file.size > 100 * 1024 * 1024) {
        message.error('File limit is upto 100MB, sending as document!');
        type = 'doc';
      }

      setSelectedFile({
        file,
        fileType: type,
        url: URL.createObjectURL(file),
      });
      setShowFileSelector(true);
    } else {
      setSelectedFile(undefined);
      message.error('No file selected');
    }
  };

  const onSend = async () => {
    // const text = inputRef.current?.innerText;

    if (shouldSendMessage) {
      const res = await shouldSendMessage(text || '');
      if (!res) return;
    }

    if (!text || text.trim().length === 0) {
      return;
    }

    // onMessageSent(text);
    setIsSaving(true);

    let data: SendMessageRequest;
    const referenceId = nanoid(12);

    if (roomId) {
      data = {
        message: text,
        messageType: 'text',
        room: roomId,
        extraData: {
          referenceId,
        },
      };
    } else {
      data = {
        message: text,
        messageType: 'text',
        fan: otherParticipantId || '',
        extraData: {
          referenceId,
        },
      };
    }

    if (replyTo) {
      data.messageRef = replyTo._id;
    }

    const form = new FormData();

    Object.keys(data).forEach((k) => {
      const key = k as keyof SendMessageRequest;

      if (key === 'extraData') {
        form.append('extraData', JSON.stringify(data.extraData));
      } else form.append(key, data[key] || '');
    });

    onMessageSent(
      {
        _id: nanoid(12),
        extraData: { referenceId },
        createdAt: new Date().toISOString(),
        senderId: id || '',
        senderName: name || '',
        senderProfilePic: profilePic || '',
        seen: false,
        seenUsers: [],
        roomDetails: {} as any,
        room: '',
        ...data,
        messageRef: {
          _id: replyTo?._id,
          messageType: replyTo?.messageType,
          message: replyTo?.message,
        },
        parentMessageSender: {
          name: replyTo?.senderName || 'User',
          _id: replyTo?.senderId || '',
          profilePicUrl: replyTo?.senderProfilePic || '',
        },
      },
      'sending',
    );

    onCloseReply();

    // inputRef.current.innerText = '';

    setText('');

    const response = await ChatAPI.sendMessage(form);

    // onMessageSent(response.data.result[0], 'sent');

    if (!roomId) {
      onNewRoomCreated(response.data.result[0].room);
    }

    setIsSaving(false);
  };

  const onSendFile = useCallback(
    async (file: FileData) => {
      // send media file here
      const nameParts = file.file.name.split('.');
      const form = new FormData();

      form.append('messageType', file.fileType);
      form.append('file', file.file);
      form.append(
        'fileName',
        file.file.name.substring(0, file.file.name.lastIndexOf('.')),
      );

      const referenceId = nanoid(12);

      form.append(
        'extraData',
        JSON.stringify({
          referenceId,
          fileSize: file.file.size,
          ext: nameParts[nameParts.length - 1],
        }),
      );

      if (roomId) form.append('room', roomId);
      else if (otherParticipantId) form.append('fan', otherParticipantId);

      if (replyTo) form.append('messageRef', replyTo._id);

      // clear selected file
      setSelectedFile(undefined);
      onCloseReply();
      // add a 'sending' message to list
      onMessageSent(
        {
          extraData: {
            referenceId,
            fileSize: file.file.size.toString(),
            ext: nameParts[nameParts.length - 1],
          },
          fileName: file.file.name,
          messageType: file.fileType,
          senderId: id || '',
          senderName: name || '',
          senderProfilePic: profilePic || '',
          room: roomId || '',
          seen: false,
          createdAt: new Date().toISOString(),
          message: '',
          seenUsers: [],
          roomDetails: {} as any,
          _id: nanoid(10),
        },
        'sending',
      );
      setShowFileSelector(false);

      // add 'sent' message to list
      const response = await ChatAPI.sendMessage(form);

      // onMessageSent(response.data.result[0], 'sent');

      if (!roomId) {
        onNewRoomCreated(response.data.result[0].room);
      }
    },
    [
      id,
      name,
      onCloseReply,
      onMessageSent,
      onNewRoomCreated,
      otherParticipantId,
      profilePic,
      replyTo,
      roomId,
    ],
  );

  useEffect(() => {
    if (replyTo && inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyTo, inputRef]);

  return (
    <>
      <div className="messageInputBox">
        {showScrollDown ? (
          <Button className="scroll-down__button" onClick={scrollToBottom}>
            <TMIcon name="chevron-down" color={colors.ICON} size={20} />
          </Button>
        ) : null}
        {replyTo ? (
          <div
            className="input-contents-reply"
            style={{ borderColor: replyNameColor }}>
            <ReplyContents
              message={replyTo.message || ''}
              messageType={replyTo.messageType}
              senderName={replyTo.senderId === id ? 'You' : replyTo.senderName}
              nameColor={replyNameColor}
            />
            <Button
              className="siteBtn noStyle circularBgHover close"
              onClick={onCloseReply}>
              <MdClose color={colors.BACKGROUND} size={24} />
            </Button>
          </div>
        ) : null}
        <div className="messageInputBox__content">
          <div className="messageInputBox__content__inputWrapper" tabIndex={-1}>
            {!noMedia && (
              <>
                <label
                  htmlFor="repliesFileUpload"
                  className="messageInputBox__content__inputWrapper__addIcon">
                  <MdAdd size={20} color={colors.BACKGROUND} />
                </label>
                <input
                  id="repliesFileUpload"
                  type="file"
                  onChange={handleFileChange}
                  disabled={isSaving}
                />
              </>
            )}
            <Input.TextArea
              ref={inputRef}
              className="messageInputBox__content__inputWrapper__input slimScroll"
              placeholder="Type a message"
              autoSize
              value={text}
              onPressEnter={(e) => {
                if (text.trim().length === 0 && !selectedFile) {
                  e.preventDefault();
                  return;
                }

                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  onSend();
                  return;
                }
              }}
              onChange={(e) => setText(e.target.value)}
              rows={1}
              style={{ resize: 'none', border: 'none', boxShadow: 'none' }}
            />
          </div>
          <Button
            disabled={isSaving}
            className="messageInputBox__content__sendIcon"
            onClick={onSend}>
            {isSaving ? (
              <Loader size="small" />
            ) : (
              <MdSend size={20} color={colors.BACKGROUND} />
            )}
          </Button>
        </div>
      </div>
      <FileSelectionModal
        file={selectedFile}
        closeModal={() => {
          setShowFileSelector(false);
          setSelectedFile(undefined);
        }}
        showModal={showFileSelector}
        onSendFile={onSendFile}
      />
    </>
  );
};

export default React.memo(ChatInputBox);
