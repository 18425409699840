import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { IoChevronDown } from 'react-icons/io5';

import CourseAPI from '../../api/CourseAPI';
import Loader from '../../components/Loader';
import MangoSelectionModal from '../../components/MangoSelectionModal/MangoSelectionModal';
import { useTheme } from '../../context/ThemeProvider';
import {
  useAppNavigate,
  useAppSelector,
  useDebounce,
} from '../../shared/hooks';
import {
  CourseListFilters,
  ICourseType,
  MangoArr,
} from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import { ISubscription } from '../../types/userTypes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import CourseListCard from './CourseListCard/CourseListCard';

interface Props {
  userId: string | null;
  subscriberMangoes: ISubscription[];
}

interface IState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  courses: ICourseType[];
  refreshing: boolean;
}

const PurchasedCourses: React.FC<Props> = ({ userId, subscriberMangoes }) => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();

  const { hostMetadata } = useAppSelector((state) => state.app);

  const [filters, setFilters] = useState<CourseListFilters>({
    category: 'all',
    mango: null,
  });
  const [searching] = useState(false);
  const [searchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [showMangoModal, setShowMangoModal] = useState(false);
  const [state, setState] = useState<IState>({
    loading: false,
    page: 1,
    hasMore: true,
    courses: [],
    refreshing: false,
  });

  const SIZE = 100;

  const loadData = async (
    pageCount: any,
    courseList = state.courses,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading && userId) {
      setState({ ...currState, loading: true });
      try {
        const res = await CourseAPI.getPurchasedCourses(pageCount, SIZE);

        let newState = { ...currState, loading: false, refreshing: false };
        if (res.status === 200 && res.data.result) {
          const newCourses = res.data.result?.filter(
            (i) => i?.creator?._id !== userId,
          );
          const updatedCourses =
            pageCount > 1 ? [...courseList, ...newCourses] : [...newCourses];
          newState = {
            ...newState,
            courses: updatedCourses,
            page: pageCount + 1,
            hasMore: res.data.result.length >= SIZE,
          };
        } else {
          message.error(res.data.message || 'Could not load courses');
          newState = {
            ...newState,
            courses: [...courseList],
            hasMore: false,
          };
        }
        setState({ ...state, ...newState });
      } catch (error: any) {
        if (
          error?.response?.data?.result &&
          typeof error?.response?.data?.result === 'string'
        )
          message.error(error?.response?.data?.result);
        else message.error('Could not load courses');
        setState({ ...state, loading: false, hasMore: false });
      }
    }
  };

  useEffect(() => {
    // const newState = {
    //   courses: [],
    //   hasMore: true,
    //   page: 1,
    //   loading: false,
    //   refreshing: false,
    // };
    setState((prev) => ({
      ...prev,
      courses: [],
      hasMore: true,
      page: 1,
    }));
    // loadData(1, [], true, false, newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getTagItem = useCallback(
    (
      value: string,
      key: string,
      onClick: () => void,
      isSelected: boolean,
      hasArrow: boolean = false,
    ) => (
      <div
        key={key}
        onClick={onClick}
        className={`tags-filter__tag ${isSelected ? 'active' : ''}`}>
        <Typography.Text>{value}</Typography.Text>
        {hasArrow && (
          <IoChevronDown
            size={18}
            color={isSelected ? colors.PRIMARY : colors.ICON}
          />
        )}
      </div>
    ),
    [colors.ICON, colors.PRIMARY],
  );

  const filteredCourses = useMemo(() => {
    let newCourses = [...state.courses];
    if (searching) {
      newCourses = newCourses.filter((course) =>
        course.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
      );
    } else {
      switch (filters.category) {
        case 'all':
          break;
        case 'expired':
          newCourses = newCourses.filter((course) => {
            return (
              course.courseValidity &&
              course.courseValidity.status === 'Expired'
            );
          });
          break;
        case 'in-progress':
          newCourses = newCourses.filter((course) => {
            const totalProgress =
              typeof course.progress === 'number'
                ? course.progress
                : course.progress.progress || 0;
            return (
              totalProgress > 0 &&
              totalProgress < 100 &&
              (!course.courseValidity ||
                course.courseValidity.status !== 'Expired')
            );
          });
          break;
        case 'completed':
          newCourses = newCourses.filter((course) => {
            const totalProgress =
              typeof course.progress === 'number'
                ? course.progress
                : course.progress.progress || 0;
            return (
              totalProgress === 100 &&
              (!course.courseValidity ||
                course.courseValidity.status !== 'Expired')
            );
          });
          break;
        case 'paid':
          newCourses = newCourses.filter((course) => {
            return course.paidCourse;
          });
          break;
      }
      if (filters.mango !== null) {
        newCourses = newCourses.filter((course) => {
          return course.mangoArr.find(
            (mango) => mango._id === filters.mango?._id,
          );
        });
      }
    }
    return newCourses;
  }, [
    state.courses,
    searching,
    debouncedSearchTerm,
    filters.category,
    filters.mango,
  ]);

  const allMangoes = useMemo(() => {
    let mangoes: MangoArr[] = [];
    state.courses.forEach((course) => {
      course.mangoArr.forEach((mango) => {
        if (!mangoes.find((m) => m._id === mango._id)) {
          mangoes.push(mango);
        }
      });
    });
    mangoes = mangoes.filter((mango) => {
      return subscriberMangoes.find((subMango) => subMango._id === mango._id);
    });
    return mangoes;
  }, [state.courses, subscriberMangoes]);

  return (
    <>
      <div className="tags-filter__container" style={{ borderTop: 'none' }}>
        <div className="tags-filter__wrapper">
          {getTagItem(
            'All',
            'all',
            () => setFilters({ ...filters, category: 'all' }),
            filters.category === 'all',
          )}
          {getTagItem(
            'In Progress',
            'in-progress',
            () => setFilters({ ...filters, category: 'in-progress' }),
            filters.category === 'in-progress',
          )}
          {getTagItem(
            'Completed',
            'completed',
            () => setFilters({ ...filters, category: 'completed' }),
            filters.category === 'completed',
          )}
          {getTagItem(
            'Expired',
            'Expired',
            () => setFilters({ ...filters, category: 'expired' }),
            filters.category === 'expired',
          )}
          {getTagItem(
            'Paid Course',
            'paid',
            () => setFilters({ ...filters, category: 'paid' }),
            filters.category === 'paid',
          )}
          {getTagItem(
            filters.mango
              ? filters.mango.title?.toTitleCase()
              : hostMetadata.offeringTitle.toTitleCase(),
            'mango',
            () => {
              setShowMangoModal(true);
            },
            Boolean(filters.mango),
            true,
          )}
        </div>
      </div>
      <div className="courses__content">
        <EasyVirtualizedScroller
          key={state.courses.map((i) => i._id).join('')}
          useParentScrollElement
          hasMore={state.hasMore}
          onLoadMore={() => loadData(state.page, state.courses)}>
          {filteredCourses.map((item) => (
            <CourseListCard
              key={item._id}
              onPress={() => {
                if (
                  item.courseValidity &&
                  item.courseValidity.status === 'Expired'
                )
                  message.error('Course Expired');
                else {
                  navigate(ROUTES.COURSE_OVERVIEW, {
                    courseId: item._id,
                  });
                }
              }}
              type="purchased"
              courseExpired={
                item.courseValidity && item.courseValidity.status === 'Expired'
              }
              title={item.title}
              coverImage={item.coverImage}
              mangoes={item.mangoArr}
              totalProgress={
                typeof item.progress === 'number'
                  ? item.progress
                  : item.progress.progress || 0
              }
              totalLectures={item.numberOfChapters}
              // paid course
              purchaseRequired={item.paidCourse ?? false}
              previewChapters={item.freeChapterCount || 0}
              mangoToSell={item.mangoTosell}
            />
          ))}
        </EasyVirtualizedScroller>
        {state.loading || !userId || state.hasMore ? (
          <Loader
            style={{
              margin: '20px 0',
              width: '100%',
            }}
          />
        ) : state.courses.length === 0 ? (
          <NoDataFound title="Whoops! No courses found!" />
        ) : (
          <Typography.Paragraph
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px 0',
            }}>
            That’s it! No more courses to show.
          </Typography.Paragraph>
        )}
      </div>
      <MangoSelectionModal
        type="single"
        show={showMangoModal}
        closeModal={() => setShowMangoModal(false)}
        selectedMangoes={filters.mango ? [filters.mango] : []}
        mangoes={allMangoes as any}
        handleSave={(mangoes) => {
          if (mangoes.length === 0) {
            setFilters({ ...filters, mango: null });
          } else {
            setFilters({ ...filters, mango: mangoes[0] });
          }
          setShowMangoModal(false);
        }}
        noMangoComponent={
          <NoDataFound
            title={`No ${hostMetadata.offeringTitles} found`}
            // subTitle={`You have not subscribed to any ${hostMetadata.offeringTitles} yet`}
          />
        }
        customTitle="Which courses you want to see?"
        customDescription={`Select a ${hostMetadata.offeringTitle} whose courses you want to see`}
      />
    </>
  );
};

export default React.memo(PurchasedCourses);
