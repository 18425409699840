import './styles.scss';

import React, { useCallback } from 'react';

import { Typography } from 'antd';
import { IoNotificationsOff } from 'react-icons/io5';

import ThreeDotSvg from '../../../../assets/svg/ThreeDotSvg';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppSelector } from '../../../../shared/hooks';
import {
  getDisplayMessage,
  getTimeDifferenceInDays,
} from '../../../../shared/messageUtils';
import {
  Message,
  Participant,
} from '../../../../types/chatModels/MangoRoomListResponse';
import ChatAvatar from '../ChatAvatar/ChatAvatar';

interface MangoRoomCardProps {
  roomTitle: string;
  unreadCount: number;
  creator?: {
    _id: string;
    name: string;
    profilePicUrl: string;
  };
  participantCount: number;
  displayParticipants: Participant[];
  displayMessages: Message[];
  displayPicture?: string;
  onClick?: () => void;
  isNotificationMuted?: boolean;
}
const MangoRoomCard: React.FC<MangoRoomCardProps> = ({
  roomTitle,
  unreadCount,
  creator,
  participantCount,
  displayParticipants,
  displayMessages,
  displayPicture,
  onClick,
  isNotificationMuted = false,
}) => {
  const { colors } = useTheme();

  const { profilePic, id } = useAppSelector((state) => state.user);

  const getCorrectProfilePic = useCallback(
    (userId: string, originalProfilePic?: string) => {
      if (id === userId) {
        return profilePic || undefined;
      }
      return originalProfilePic;
    },
    [id, profilePic],
  );

  const getAvatar = (url: string, index: number) => (
    <div key={`${index}`} style={{ marginLeft: index > 0 ? -8 : 0, zIndex: 1 }}>
      <ChatAvatar
        size={22}
        url={url}
        style={{ backgroundColor: colors.BACKGROUND }}
        border={1}
      />
    </div>
  );

  const getParticipantsGroup = (
    displayParticipantsArray: Participant[],
    totalParticipants: number,
  ) => {
    if (displayParticipantsArray?.length >= 3) {
      return (
        <>
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 1]
              ?.profilePicUrl,
            0,
          )}
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 2]
              ?.profilePicUrl,
            1,
          )}
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 3]
              ?.profilePicUrl,
            2,
          )}
          {totalParticipants - displayParticipantsArray.length > 0 ? (
            <div className="participant-extra-text__wrapper">
              <Typography.Text className="participant-extra-text">
                +{totalParticipants - displayParticipantsArray.length}
              </Typography.Text>
            </div>
          ) : null}
        </>
      );
    }
    return displayParticipantsArray.map((participant, index) =>
      getAvatar(participant?.profilePicUrl, index),
    );
  };

  const recentMessageItem = (item: Message) => (
    <div key={item._id} className="mango-room__recent-message-item">
      <ChatAvatar
        size={20}
        url={getCorrectProfilePic(item.senderId, item.senderProfilePic)}
        border={1}
        selected={!item.seen}
        selectedColor={colors.LINK}
      />
      <Typography.Title
        level={5}
        className="mango-room__recent-message-item__text"
        ellipsis={{ rows: 1, expandable: false }}
        style={{ color: item.seen ? colors.TEXT4 : colors.TEXT2 }}>
        {item.senderName?.split(' ')[0]}: {getDisplayMessage(item)}
      </Typography.Title>
      <Typography.Text
        className="mango-room__recent-message-item__time"
        style={{ color: !item.seen ? colors.LINK : colors.TEXT4 }}>
        {getTimeDifferenceInDays(item.createdAt)}
      </Typography.Text>
    </div>
  );

  return (
    <div className="mango-room-card" onClick={onClick}>
      <div className="mango-room-card__header">
        <ChatAvatar
          size={48}
          selected={unreadCount > 0}
          name={roomTitle}
          url={
            displayPicture ||
            getCorrectProfilePic(creator?._id || '', creator?.profilePicUrl)
          }
          selectedColor={colors.LINK}
        />
        <div className="mango-room-card__header__content">
          <Typography.Title level={5} ellipsis={{ rows: 1, expandable: false }}>
            {creator?.name || 'Creator'}
          </Typography.Title>
          <Typography.Title level={3} ellipsis={{ rows: 1, expandable: false }}>
            {roomTitle}
          </Typography.Title>
        </div>

        {isNotificationMuted && (
          <IoNotificationsOff
            color={colors.TEXT3}
            size={20}
            style={{
              flexShrink: 0,
            }}
          />
        )}
        {unreadCount > 0 && (
          <div
            className="mango-room-card__header__unread-count"
            style={{
              marginLeft: isNotificationMuted ? 6 : 0,
            }}>
            <Typography.Text className="mango-room-card__header__unread-count__text">
              {unreadCount}
            </Typography.Text>
          </div>
        )}
      </div>
      {displayMessages && displayMessages.length > 0 && (
        <div className="mango-room-card__recent-messages">
          {displayMessages
            .slice(0, 3)
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
            .map((item) => recentMessageItem(item))}
        </div>
      )}
      <ThreeDotSvg width={20} height={20} />
      {participantCount > 0 && (
        <div className="mango-room-card__participants">
          {getParticipantsGroup(
            displayParticipants,
            // .filter((p) => p._id !== userDetails.id),
            participantCount,
          )}
          <Typography.Text className="mango-room-card__participants__extra--text">
            joined this room
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default React.memo(MangoRoomCard);
