/* eslint-disable @typescript-eslint/no-unused-vars */
const dev1 = {
  API_ENDPOINT: 'https://dev1api.tagmango.com',
  HOST: 'development1web.tagmango.com',
  CHAT_ENDPOINT: 'https://testing.chat.tagmango.com',
  SOCKET_ENDPOINT: 'wss://socket.testing.tagmango.com/',
};

const testing = {
  API_ENDPOINT: 'https://testing-api-v2.tagmango.com',
  HOST: 'testing.tagmango.com',
  CHAT_ENDPOINT: 'https://testing.chat.tagmango.com',
  SOCKET_ENDPOINT: 'wss://socket.testing.tagmango.com/',
};

const testingWl = {
  API_ENDPOINT: 'https://testing-api-v2.tagmango.com',
  HOST: 'testingwl.tagmango.in',
  CHAT_ENDPOINT: 'https://testing.chat.tagmango.com',
  SOCKET_ENDPOINT: 'wss://socket.testing.tagmango.com/',
};

const prod = {
  API_ENDPOINT: 'https://api-prod-new.tagmango.com',
  HOST: 'tagmango.com',
  CHAT_ENDPOINT: 'https://v2.new-chat-api.tagmango.com',
  SOCKET_ENDPOINT: 'wss://v2.socket.tagmango.com/',
};

// let config = dev1;
// let config = testing;
let config = testingWl;
// let config = prod;

config = {
  ...config,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || config.API_ENDPOINT,
  HOST: process.env.REACT_APP_HOST || config.HOST,
  CHAT_ENDPOINT: process.env.REACT_APP_CHAT_ENDPOINT || config.CHAT_ENDPOINT,
  SOCKET_ENDPOINT:
    process.env.REACT_APP_SOCKET_ENDPOINT || config.SOCKET_ENDPOINT,
};

export default config;
