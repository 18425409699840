/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Button,
  Typography,
} from 'antd';

import FeedAPI from '../../../../api/FeedAPI';
import ShareBottomSheet
  from '../../../../components/Modals/ShareBottomSheet/ShareBottomSheet';
import { updatePost } from '../../../../context/activityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../shared/hooks';
import { nFormat } from '../../../../shared/utils';
import LikeButton from './LikeButton';

type Props = {
  post: any;
  onCommentsPressed: () => void;
  updateActualPost?: (post: any) => void;
};

const PostStats: React.FC<Props> = ({
  post,
  onCommentsPressed,
  updateActualPost,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const { shortUrl, deeplinkUrl } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const [showShareBottomSheet, setShowShareBottomSheet] = React.useState(false);

  async function togglelike(val: boolean) {
    const res = await FeedAPI.likeUnlikePost(post._id);
    if (res.status === 200) {
      dispatch(
        updatePost({
          ...post,
          liked: val,
          likedBy: val
            ? [...post.likedBy, userDetails.id]
            : [...post.likedBy.filter((i: string) => i !== userDetails.id)],
        }),
      );
      if (updateActualPost)
        updateActualPost({
          ...post,
          liked: val,
          likedBy: val
            ? [...post.likedBy, userDetails.id]
            : [...post.likedBy.filter((i: string) => i !== userDetails.id)],
        });
    }
  }

  const like = (value: boolean) => {
    togglelike(value);
  };

  const liked = {
    value: post.liked,
    count: post.likedBy?.length || 0,
  };

  const getSingularOrPlural = (val: number, text: string) =>
    val > 1 ? `${text}s` : text;

  const getPostLink = () => {
    let url = `${deeplinkUrl}post/${post._id}`;
    if (post.shortUrl) {
      url = `${shortUrl}${post.shortUrl}`;
    }
    return url;
  };

  return (
    <>
      <div className="postStats">
        {(liked.count > 0 || (post.commentCount || 0) > 0) && (
          <div className="postStatsTextWrapper" onClick={onCommentsPressed}>
            {liked.count > 0 && (
              <Typography.Text className="_no">
                {nFormat(liked.count)}{' '}
                {getSingularOrPlural(liked.count, 'like')}
              </Typography.Text>
            )}
            {liked.count > 0 && (post.commentCount || 0) > 0 && (
              <Typography.Text className="_no separator">•</Typography.Text>
            )}
            {
              // FIXME: replyCount is not being updated
            }
            {(post.commentCount || 0) > 0 && (
              <Typography.Text className="_no">
                {nFormat(post.commentCount + (post.replyCount || 0))}{' '}
                {getSingularOrPlural(
                  post.commentCount + (post.replyCount || 0),
                  'comment',
                )}
              </Typography.Text>
            )}
          </div>
        )}
        <div className="postStatsWrapper">
          <div className="postLikeWrapper">
            <LikeButton isLiked={liked.value} like={like} />
          </div>
          <div className="postCommentWrapper">
            <Button className="siteBtn noStyle" onClick={onCommentsPressed}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.928"
                height="20.928"
                viewBox="0 0 20.928 20.928">
                <path
                  id="_002-comment"
                  data-name="002-comment"
                  d="M18,0H2.174A2.177,2.177,0,0,0,0,2.174v11.6a2.176,2.176,0,0,0,2.174,2.174H7.191l1.661,3.323a1.437,1.437,0,0,0,1.237.911,1.437,1.437,0,0,0,1.237-.911l1.662-3.323H18a2.176,2.176,0,0,0,2.174-2.174V2.174A2.176,2.176,0,0,0,18,0ZM19,13.77a.993.993,0,0,1-.992.992H12.622a.591.591,0,0,0-.529.327l-1.825,3.65a.719.719,0,0,1-.179.247.719.719,0,0,1-.179-.247l-1.825-3.65a.591.591,0,0,0-.529-.327H2.174a.993.993,0,0,1-.992-.992V2.174a.993.993,0,0,1,.992-.992H18A.993.993,0,0,1,19,2.174Zm0,0"
                  transform="translate(0.375 0.375)"
                  fill={colors.ICON}
                  stroke={colors.ICON}
                  strokeWidth="0.75"
                />
              </svg>
            </Button>
          </div>
          <div className="postShareWrapper">
            <Button
              className="siteBtn noStyle"
              onClick={() => {
                setShowShareBottomSheet(true);
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.5"
                height="20.603"
                viewBox="0 0 15.5 20.603">
                <g
                  id="export_1_"
                  data-name="export (1)"
                  transform="translate(-68.641 0.354)">
                  <path
                    id="Path_682"
                    data-name="Path 682"
                    d="M83.266,172.228H81.391v1.25h1.25v11.25h-12.5v-11.25h1.25v-1.25H69.516a.625.625,0,0,0-.625.625v12.5a.625.625,0,0,0,.625.625h13.75a.625.625,0,0,0,.625-.625v-12.5A.625.625,0,0,0,83.266,172.228Z"
                    transform="translate(0 -165.978)"
                    fill={colors.ICON}
                    stroke={colors.ICON}
                    strokeWidth="0.5"
                  />
                  <path
                    id="Path_683"
                    data-name="Path 683"
                    d="M163.618,2.393V11.25h1.25V2.393l2.683,2.683.884-.884L164.243,0l-4.192,4.192.884.884Z"
                    transform="translate(-87.852)"
                    fill={colors.ICON}
                    stroke={colors.ICON}
                    strokeWidth="0.5"
                  />
                </g>
              </svg>
            </Button>
          </div>
        </div>
      </div>
      <ShareBottomSheet
        showModal={showShareBottomSheet}
        closeModal={() => setShowShareBottomSheet(false)}
        shareLink={getPostLink()}
        title="Check out the post on TagMango"
      />
    </>
  );
};

export default React.memo(PostStats);
