import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomRouter } from '../types/baseTypes';
import { HostMetadata } from '../types/HostMetadata';
import { ROUTES } from '../types/routes';
import { ICreatorCategory } from '../types/userTypes';

interface ITagMeta {
  value: string;
  creatorId: string;
}

interface IAppSliceState {
  token: string | null;
  appReady: boolean;
  isLoggedIn: boolean;
  cookie: string | null;
  ip: string;
  categories: ICreatorCategory[];
  theme: 'light' | 'dark';
  userTheme: 'light' | 'dark' | 'system';
  hostMetadata: HostMetadata;
  isTagMango: boolean;
  shortUrl: string;
  deeplinkUrl: string;
  mDeeplinkUrl: string;
  deferredPrompt: any;
  isDRMUser: boolean;
  tags: Record<string, ITagMeta>;
  isGamificationEnabled?: boolean;
  isCommunityEnabled?: boolean;

  customRouter: CustomRouter;
}

const defaultCustomRouter: CustomRouter = {
  homeRoutes: [
    {
      name: ROUTES.FEED,
      config: {
        title: 'Feed',
        path: '/activity',
        key: 'feed',
      },
    },
    {
      name: ROUTES.COURSES,
      config: {
        title: 'Courses',
        path: '/courses',
        key: 'courses',
      },
    },
    {
      name: ROUTES.VIDEO_CALL,
      config: {
        title: 'Video calls',
        path: '/video-call',
        key: 'video-call',
      },
    },
    {
      name: ROUTES.MESSAGES,
      config: {
        title: 'Messages',
        path: '/messages',
        key: 'messages',
      },
    },
  ],
  menuRoutes: [],
  initialRoutes: {
    default: {
      route: ROUTES.FEED,
      path: '/activity',
      isTMProject: true,
    },
  },
};

const initialState: IAppSliceState = {
  token: null,
  appReady: false,
  isLoggedIn: false,
  cookie: null,
  ip: '',
  categories: [],
  theme: 'light',
  userTheme: 'light',
  hostMetadata: {
    __v: 0,
    _id: '',
    appName: 'TagMango',
    brandname: 'tagmango',
    createdAt: Date.now().toString(),
    creator: {
      _id: '1234',
      name: 'WL Creator',
      userSlug: 'wl-creator',
    },
    host: 'tagmango.com',
    logo: 'https://assets.website-files.com/6302e597fbb6ee54fbee3b8a/632aa73a05712d087d9bb4da_256x256.png',
    offeringTitle: 'mango',
    offeringTitles: 'mangoes',
    updatedAt: Date.now().toString(),
    iosShareLink: 'https://apps.apple.com/in/app/tagmango/id1547247825',
    androidShareLink:
      'https://play.google.com/store/apps/details?id=com.tagmango.app',
  },
  isTagMango: true,
  shortUrl: 'https://tagmango.app/',
  deeplinkUrl: 'https://tagmango.com/',
  mDeeplinkUrl: 'https://m.tagmango.com/',
  deferredPrompt: null,
  isDRMUser: false,
  tags: {},
  isGamificationEnabled: false,

  customRouter: defaultCustomRouter,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setAppReady: (state, action: PayloadAction<boolean>) => {
      state.appReady = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setCookie: (state, action: PayloadAction<string | null>) => {
      state.cookie = action.payload;
    },
    setIp: (state, action: PayloadAction<string>) => {
      state.ip = action.payload;
    },
    setCategories: (state, action: PayloadAction<any[]>) => {
      Object.assign(state, { categories: action.payload });
    },
    setHostMetadata: (state, action: PayloadAction<Partial<HostMetadata>>) => {
      Object.assign(state.hostMetadata, {
        ...action.payload,
        offeringTitle: action.payload.offeringTitle?.toLowerCase() || 'mango',
        offeringTitles:
          action.payload.offeringTitles?.toLowerCase() || 'mangoes',
      });
      state.isTagMango =
        state.hostMetadata.host.includes('tagmango.com') ||
        state.hostMetadata.host.includes('localhost');
      if (
        action.payload.securityLevel &&
        typeof action.payload.securityLevel === 'number' &&
        action.payload.securityLevel > 1
      ) {
        state.isDRMUser = true;
      }
    },
    setIsGamificationEnabled: (state, action: PayloadAction<boolean>) => {
      state.isGamificationEnabled = action.payload;
    },
    setIsCommunityEnabled: (state, action: PayloadAction<boolean>) => {
      state.isCommunityEnabled = action.payload;
    },
    setTheme: (state, action: PayloadAction<'light' | 'dark' | undefined>) => {
      state.theme =
        action.payload || (state.theme === 'light' ? 'dark' : 'light');
    },
    setUserTheme: (
      state,
      action: PayloadAction<'light' | 'dark' | 'system'>,
    ) => {
      localStorage.setItem('theme', action.payload);
      state.userTheme = action.payload;
    },
    setNativeDomain: (state, action: PayloadAction<string>) => {
      state.shortUrl =
        action.payload.includes('tagmango.com') ||
        action.payload.includes('localhost')
          ? 'https://tagmango.app/'
          : `https://${action.payload}/l/`;
      state.deeplinkUrl = `https://${action.payload}/`;
      state.mDeeplinkUrl = `https://m.${action.payload}/`;
    },
    setDeferredPrompt: (state, action: PayloadAction<any>) => {
      state.deferredPrompt = action.payload;
    },
    setCustomRouter: (state, action: PayloadAction<Partial<CustomRouter>>) => {
      Object.assign(state.customRouter, action.payload);
    },
    setTags: (
      state,
      action: PayloadAction<
        { key: string; value: string; creatorId: string }[]
      >,
    ) => {
      const tags = action.payload.reduce((acc, curr) => {
        acc[curr.key] = { value: curr.value, creatorId: curr.creatorId };
        return acc;
      }, {} as Record<string, ITagMeta>);
      state.tags = tags;
    },
  },
});

export const {
  setToken,
  setAppReady,
  setLoggedIn,
  setCookie,
  setIp,
  setCategories,
  setTheme,
  setUserTheme,
  setHostMetadata,
  setIsGamificationEnabled,
  setIsCommunityEnabled,
  setNativeDomain,
  setDeferredPrompt,
  setTags,
  setCustomRouter,
} = appSlice.actions;

export default appSlice.reducer;
