import React from 'react';

import { Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import Loader from '../../components/Loader';
import { useAppSelector } from '../../shared/hooks';
import { IWebinar } from '../../types/feedTypes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import VideoCallCard from '../Feed/VideoCall/VideoCallCard';

interface Props {
  refreshing: boolean;
  onRefreshed: () => void;
  hasMore?: boolean;
  loading?: boolean;
  webinarType: 'upcoming' | 'completed';
  webinars: IWebinar[];
  onJoinBtnClick?: (data: IWebinar) => void;
  getMoreWebinars: () => Promise<void>;
  onMenuPress?: (data: IWebinar) => void;
  onPostButtonPress?: (data: IWebinar) => void;
  onDetailsPress?: (data: IWebinar) => void;
}

const WorkshopFlatlist: React.FunctionComponent<Props> = ({
  loading,
  hasMore,
  webinarType,
  webinars,
  getMoreWebinars,
  onMenuPress,
  onDetailsPress,
  onJoinBtnClick,
}) => {
  const userDetails = useAppSelector((state) => state.user);

  const conditionfordownload = (webinar: IWebinar) => {
    const recordings = [
      ...(webinar?.zoomRecordingDetails || []),
      ...(webinar?.hmsRecordingUrlArr || []),
      ...(webinar?.zoomRecordingUrlArr || []),
      ...(webinar?.zoomRecordingUrlS3Arr || []),
    ];
    if (
      (userDetails.type === 'creator_completed' ||
        userDetails.type === 'dual') &&
      webinar?.videocallType === 'webinar' &&
      webinar?.status === 'completed' &&
      webinar?.creator === userDetails.id &&
      recordings.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="scroll-element workshops__content">
      <EasyVirtualizedScroller
        onLoadMore={() => getMoreWebinars()}
        useParentScrollElement={true}
        hasMore={hasMore}>
        {webinars.map((item, index) => (
          <div style={{ marginBottom: 12 }}>
            <VideoCallCard
              key={item._id}
              data={item}
              webinarType={webinarType}
              host={
                item.participants.find((el: any) => el._id === item.creator) ||
                item.participants[0]
              }
              onMenuClick={
                webinarType === 'completed' &&
                !conditionfordownload(webinars[index])
                  ? undefined
                  : onMenuPress
                    ? () => onMenuPress(item)
                    : undefined
              }
              onSelect={() => {
                if (onDetailsPress) onDetailsPress(item);
              }}
              onJoinBtnClick={() => {
                if (onJoinBtnClick) onJoinBtnClick(item);
              }}
            />
          </div>
        ))}
      </EasyVirtualizedScroller>
      {loading === true || hasMore === true ? (
        <div
          style={{
            marginTop: 12,
            textAlign: 'center',
          }}>
          <Loader />
        </div>
      ) : webinars.length === 0 ? (
        <NoDataFound
          title="Whoops! No video calls found!"
          subtitle={
            webinarType === 'upcoming'
              ? 'Looks like there are no group video calls yet! 🙌'
              : 'Looks like no video calls have been set-up so far!'
          }
        />
      ) : (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            marginBlock: '20px 85px',
          }}>
          You&#39;ve reached the end of video calls.
        </Typography.Paragraph>
      )}
    </div>
  );
};

export default React.memo(WorkshopFlatlist);
